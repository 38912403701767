import React from 'react'
import Header from './Header'
import Footer from './Footer'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Getintouch from './Getintouch';

function Residences() {

    let options = {
        loop: false,
        margin: 20,
        navText: ["<img src='/websiteimages/left-arrow-slider.png'>", "<img src='/websiteimages/right-arrow-slider.png'>"],
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    return (
        <>
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                <img src="/websiteimages/residences-img.jpg" alt='' />
                <div className='aurealestate-content'>
                    <h1>Residences</h1>
                </div>
            </div>
            <div className='advisory-main'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Residences</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='service-div'>
                    <div className='advisoryline'></div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 jointventures'>
                                <h2>Overview</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tristique enim quis lectus 
                                    hendrerit, ac dictum dui tristique. Nam pretium lacinia ipsum, quis porttitor nunc mattis ac. 
                                    Maecenas egestas ultrices purus, egestas dapibus libero viverra eu.</p>
                                {/* {jointventure.overview_desc?parse(jointventure.overview_desc):null} */}
                                <img src="/websiteimages/ourproject-img1.png" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ourprojects'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='ourprojectstop'>
                                    <h2>Our Projects</h2>
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            {/* <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="All" aria-selected="true">All</button> */}
                                            <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#delivered" type="button" role="tab" aria-controls="delivered" aria-selected="false">Delivered</button>
                                            {/* <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#underconstruction" type="button" role="tab" aria-controls="underconstruction" aria-selected="false">Under Construction</button> */}
                                        </div>
                                    </nav>
                                </div>
                                <div className='ourprojectsbottom'>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/arihant-enclave-img.jpg' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Arihant Enclave</h4>
                                                                    <h6>Independent homes for the individual in you.
Community living for the socially connected in you.
                                                                    </h6>
                                                                    <p>Experience the comfort of your own independent home while nurturing your unique individuality. Engage in the warmth of community living, fostering connections that enrich your social spirit.
                                                                    </p>
                                                                    <a href='/arihant-enclave' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="tab-pane fade" id="delivered" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='ourprojectscontent'>
                                                            <img src='/websiteimages/arihant-enclave-img.jpg' alt='' />
                                                            <div className='ourprojectscontentdiv'>
                                                                <h4>Big Enclave</h4>
                                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry.
                                                                </h6>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                    since the 1500s, when an unknown printer took a galley of type and
                                                                    scrambled it to make a type specimen book.
                                                                </p>
                                                                <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class="tab-pane fade" id="underconstruction" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='ourprojectscontent'>
                                                            <img src='/websiteimages/arihant-enclave-img.jpg' alt='' />
                                                            <div className='ourprojectscontentdiv'>
                                                                <h4>Big Enclave</h4>
                                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry.
                                                                </h6>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                    since the 1500s, when an unknown printer took a galley of type and
                                                                    scrambled it to make a type specimen book.
                                                                </p>
                                                                <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Residences