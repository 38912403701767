import React from 'react'
import Header from './Header'
import Footer from './Footer'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Getintouch from './Getintouch';
// import { fetchJointVentureGeneral } from '../controler/jointventure/Jointventure';
// import { imageUrl } from '../controler/ApiHelper';
// import parse from 'html-react-parser';

function Commercial() {
    // const [jointventure, setJointVenture] = useState({})
    // useEffect(() => {
    //     async function jointventuresgeneral() {
    //         const data = await fetchJointVentureGeneral();
    //         setJointVenture(data.data[0]);
    //         console.log("joint venture data", data.data);
    //     }
    //     jointventuresgeneral();
    // }, [])

    let options = {
        loop: false,
        margin: 20,
        navText: ["<img src='/websiteimages/left-arrow-slider.png'>", "<img src='/websiteimages/right-arrow-slider.png'>"],
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    return (
        <>
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                <img src="/websiteimages/commercial-banner-img.jpg" alt='' />
                <div className='aurealestate-content'>
                    <h1>Commercial</h1>
                </div>
            </div>
            <div className='advisory-main'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Commercial</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='service-div'>
                    <div className='advisoryline'></div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 jointventures'>
                                <h2>Overview</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tristique enim quis lectus 
                                    hendrerit, ac dictum dui tristique. Nam pretium lacinia ipsum, quis porttitor nunc mattis ac. 
                                    Maecenas egestas ultrices purus, egestas dapibus libero viverra eu.</p>
                                {/* {jointventure.overview_desc?parse(jointventure.overview_desc):null} */}
                                <img src="/websiteimages/commercial-overview-img.jpg" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ourprojects'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='ourprojectstop'>
                                    <h2>Our Projects</h2>
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            {/* <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="All" aria-selected="true">All</button> */}
                                            {/* <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#delivered" type="button" role="tab" aria-controls="delivered" aria-selected="false">Delivered</button> */}
                                            <button class="nav-link active" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#underconstruction" type="button" role="tab" aria-controls="underconstruction" aria-selected="false">Under Construction</button>
                                        </div>
                                    </nav>
                                </div>
                                <div className='ourprojectsbottom'>
                                    <div class="tab-content" id="nav-tabContent">
                                        {/* <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/commercial-ourproject-img.jpg' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Arihant Enclave</h4>
                                                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry.
                                                                    </h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                        since the 1500s, when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book.
                                                                    </p>
                                                                    <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/commercial-ourproject-img.jpg' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Big Enclave</h4>
                                                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry.
                                                                    </h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                        since the 1500s, when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book.
                                                                    </p>
                                                                    <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/commercial-ourproject-img.jpg' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Big Enclave</h4>
                                                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry.
                                                                    </h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                        since the 1500s, when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book.
                                                                    </p>
                                                                    <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="tab-pane fade show active" id="delivered" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='ourprojectscontent'>
                                                            <img src='/websiteimages/commercial-ourproject-img.jpg' alt='' />
                                                            <div className='ourprojectscontentdiv'>
                                                                <h4>Family Hub</h4>
                                                                <h6>Nestled within the verdant landscapes of Aditya World City in Ghaziabad, NH 24, a vibrant community hub that elevates your quality of life.
                                                                </h6>
                                                                <p>Whether you're in search of the latest fashion trends, culinary delights, or simply a tranquil place to unwind amidst nature, Family Hub caters to your diverse lifestyle needs.
                                                                </p>
                                                                <a href='/family-hub' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="tab-pane fade" id="underconstruction" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='ourprojectscontent'>
                                                            <img src='/websiteimages/commercial-ourproject-img.jpg' alt='' />
                                                            <div className='ourprojectscontentdiv'>
                                                                <h4>Big Enclave</h4>
                                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry.
                                                                </h6>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                    since the 1500s, when an unknown printer took a galley of type and
                                                                    scrambled it to make a type specimen book.
                                                                </p>
                                                                <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Commercial