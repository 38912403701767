import React from "react";
// import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import './main.css'
import './responsive.css'
import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useFormik } from 'formik';
import { mainFormSchema } from "./../schemas";
import { fetchEnquiryForm } from "./../controler";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import { Modal } from 'antd';
import { imageUrl } from "../controler/ApiHelper";
import moment from "moment";

import Cookies from "js-cookie";
import { fetchAarihantYear, fetchArihantConstructionUpdatesDataByMonth } from "../controler/arihant-enclave/Arihantenclave";
import Metatag from "../homepage/Metatag";

const initialValues = {
	firstname: "",
	lastname: "",
	email: "",
	phone: "",
	comment: "",
	termsandcondition: false,
};




function HomePage() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [arihantyear, setArihantYear] = useState([]);
	const [arihantdatabymonth, setArihantDataByMonth] = useState({});
	const [selectedDrpYear, setSelectedDrpYear] = useState();
	const carouselRef = React.useRef(null);


	const navigate = useNavigate()
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
		initialValues: initialValues,
		validationSchema: mainFormSchema,
		onSubmit: async (values, action) => {

			// let getQueryParams = searchParams.get();

			let fdata = { ...values };

			for (let entry of searchParams.entries()) {
				fdata[entry[0]] = entry[1];
			}

			// console.log("values::", values,fdata);

			const data = await fetchEnquiryForm(fdata)
			console.log("formik Form Handling ", data, fdata);
			action.resetForm();
			navigate('/thank-you')
		}

	})

	let options = {
		loop: false,
		margin: 10,
		nav: false,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 3
			},
			1000: {
				items: 3
			}
		}
	}

	useEffect(() => {

		let acceptedDisclamer = Cookies.get("disclaimer-accepted");

		// setIsModalOpen(true);


		if (!acceptedDisclamer) {
			setIsModalOpen(true);
		}

		const selectBody = document.querySelector('body');
		const selectHeader = document.querySelector('#header');

		function toggleScrolled() {
			if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
			window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
		}

		document.addEventListener('scroll', toggleScrolled);
		window.addEventListener('load', toggleScrolled);

		/**
		 * Mobile nav toggle
		 */
		const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');

		function mobileNavToogle() {
			document.querySelector('body').classList.toggle('mobile-nav-active');
			mobileNavToggleBtn.classList.toggle('bi-list');
			mobileNavToggleBtn.classList.toggle('bi-x');
		}
		mobileNavToggleBtn.addEventListener('click', mobileNavToogle);

		document.querySelectorAll('#navmenu a').forEach(navmenu => {
			navmenu.addEventListener('click', () => {
				if (document.querySelector('.mobile-nav-active')) {
					mobileNavToogle();
				}
			});

		});
		/**
	 * Toggle mobile nav dropdowns
	 */
		document.querySelectorAll('.navmenu .has-dropdown i').forEach(navmenu => {
			navmenu.addEventListener('click', function (e) {
				if (document.querySelector('.mobile-nav-active')) {
					e.preventDefault();
					this.parentNode.classList.toggle('active');
					this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
					e.stopImmediatePropagation();
				}
			});
		})

		/**
 * Scroll top button
 */
		let scrollTop = document.querySelector('.scroll-top');

		function toggleScrollTop() {
			if (scrollTop) {
				window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
			}
		}
		scrollTop.addEventListener('click', (e) => {
			e.preventDefault();
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		});

		window.addEventListener('load', toggleScrollTop);
		document.addEventListener('scroll', toggleScrollTop);

		async function arihantYearr() {
			const data = await fetchAarihantYear();
			console.log("...................", data);
			setArihantYear(data.data);
			arihantdatabymonthFunc(data.data[data.data.length - 1].year);
		}
		arihantYearr();






	}, []);

	async function arihantdatabymonthFunc(currYear) {

		// let option_selected = '2024-02';
		setSelectedDrpYear(currYear);
		const data = await fetchArihantConstructionUpdatesDataByMonth(currYear);
		console.log("...................", data);

		let t_data = data.data;
		setArihantDataByMonth(() => ({ data: t_data }));
	}


	function saveCookie() {

		Cookies.set("disclaimer-accepted", "true", {
			expires: 180,
		});
		setIsModalOpen(false);
	}
	async function getData(e) {

		setArihantDataByMonth();
		let val = { "month": "" }
		val.month = e.target.value;
		console.log("hereeeeeeeeeeeee", e.target.value, val);

		setSelectedDrpYear(e.target.value);

		const dataa = await fetchArihantConstructionUpdatesDataByMonth(e.target.value);
		console.log("2222222", dataa,);
		setArihantDataByMonth(() => ({ data: dataa.data }));
	}

	return (

		<>
			<Metatag
				link="arihant-enclave"
				title="Luxury Contemporary Home Plans at Arihant Enclave | AU Real Estate"
				description="Explore luxury contemporary home plans at Arihant Enclave, Ghaziabad. Buy your dream house in Ghaziabad with AU Real Estate."
			/>
			<Modal
				className="modalone"
				open={isModalOpen}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				closeIcon={null}
			>
				<h1 style={{position:"absolute", zIndex: -100}}>Luxury Home Plans- Arihant Enclave</h1>
				<h2>Disclaimer</h2>
				<p>Thank you for visiting our website </p>
				<p> This disclaimer will be applicable to the website. By using or accessing the website you agree with the disclaimer without any qualification or limitation. The company reserves the right to add, alter or delete material from the website at any time and may, at any time, revise these terms without notifying you. You are bound by any such amendments and the company therefore advise that you periodically visit this page to review the current terms. </p>
				<p>The websites and all its content are provided on an "as is" and "as available" basis. No information given under this website creates a warranty or expand the scope of any warranty that cannot be claimed under the applicable laws. Your use of the website is solely at your own risk. This website is for guidance only. It does not constitute part of an offer or contract. Design & specifications are subject to change without any prior notice. Computer generated images are the artist's impression and are only an indicative of the actual designs. </p>
				<p>The contents are being modified in terms of the stipulations / recommendations under the Real Estate Regulation Act, 2016 and rules made thereunder ("RERA") and accordingly may not be fully in line thereof as of date. You are therefore required to verify all the details, including area, amenities, services, terms of sales and payments and other relevant terms independently with the company prior to concluding any decision for buying any unit(s) in any of the said projects. Till such time the details are fully updated, the said information will not be construed as an advertisement. To find out more about a project / development, please telephone our sales centre or visit our sales office during office hours and speak to any of our sales staff.</p>

				<button className="btnmain" onClick={saveCookie}>Continue</button>
			</Modal>

			<header id="header" className="header fixed-top d-flex align-items-center">
				<div className="container d-flex align-items-center justify-content-between">

					<a href="/" className="logo d-flex align-items-center me-auto me-xl-0">
						<img src="images/logo.png" alt="" />
					</a>
					<nav id="navmenu" className="navmenu">
						<ul>
							<li><a href="#hero" className="active">Overview</a></li>
							<li><a href="#lifestyle">AE Lifestyle</a></li>
							<li><a href="#whychooseus">Why Choose Us</a></li>
							<li><a href="#layout">Layout Plan</a></li>
							<li><a href="#gallery">Gallery</a></li>
							<li><a href="#location">Location</a></li>
							<li><span><a href="images/AE_Brochure-24Sep23-final.pdf" target="_blank">Download Brochure</a> </span></li>
						</ul>
						{/* <FontAwesomeIcon className="mobile-nav-toggle d-xl-none bi bi-list" icon="fa-solid faBarsStaggered" /> */}
						{/* <FontAwesomeIcon icon="fa-solid fa-bars-staggered" /> */}
						{/* {menuIcon} */}
						<img src="images/toggle-icon.png" alt="" className="mobile-nav-toggle d-xl-none bi bi-list" />
					</nav>

				</div>
			</header>
			<section id="hero" className="hero">
				<img src="images/main-banner1.jpg" alt="Arihant Enclave" className="d-none d-lg-block" />
				<img src="images/Au-mobile-banner.png" alt="Arihant Enclave" className="d-block d-lg-none" />
				<div className="container">
					<form onSubmit={handleSubmit}>
						<input type="hidden" name="utm_source" id="utm_source" value="" />
						<input type="hidden" name="utm_medium" id="utm_medium" value="" />
						<input type="hidden" name="utm_campaign" id="utm_campaign" value="" />

						<div className="formdiv">
							<h2>Enquire Now</h2>

							<div className="row">
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="name1" className="form-control" id="name1" placeholder="your first name"
												name="firstname"
												autoComplete="off"
												value={values.firstname}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="name1">First Name</label>
										</div>

										{/* <input type="text" name="firstname" className="form-control" placeholder="First Name"
											autoComplete="off"
											value={values.firstname}
											onChange={handleChange}
											onBlur={handleBlur} /> */}
										{errors.firstname && touched.firstname ? <p className="form-errors">{errors.firstname}</p> : null}
									</div>
								</div>
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="name1" className="form-control" id="name2" placeholder="your last name"
												name="lastname"
												autoComplete="off"
												value={values.lastname}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="name2">Last Name</label>
										</div>
										{/* <input type="text" name="lastname" className="form-control" placeholder="Last Name"
											value={values.lastname}
											onChange={handleChange}
											onBlur={handleBlur} /> */}
										{errors.lastname && touched.lastname ? <p className="form-errors">{errors.lastname}</p> : null}
									</div>
								</div>
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="text" maxLength={10} className="form-control" id="phone" placeholder="your phone number"
												name="phone"
												autoComplete="off"
												value={values.phone}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="phone">Phone Number</label>
										</div>
										{/* <input type="text" name="phone" className="form-control" placeholder="Phone Number"
											value={values.phone}
											onChange={handleChange}
											onBlur={handleBlur} /> */}
										{errors.phone && touched.phone ? <p className="form-errors">{errors.phone}</p> : null}
									</div>
								</div>
								<div className="col-lg-6 col-sm-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="email" className="form-control" id="email" placeholder="your email id"
												name="email"
												autoComplete="off"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="email">Email ID</label>
										</div>
										{errors.email && touched.email ? <p className="form-errors">{errors.email}</p> : null}
									</div>
									{/* <div className="form-group">
										<input type="text" name="email" className="form-control" placeholder="Email ID"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur} />
										{errors.email && touched.email ? <p className="form-errors">{errors.email}</p> : null}
									</div> */}
								</div>
								<div className="col-12">
									<div className="form-group">
										<div className="form-floating">
											<textarea type="text" name="comment" className="form-control" placeholder="Message"
												value={values.comment}
												onChange={handleChange}
												onBlur={handleBlur}></textarea>
											<label htmlFor="Message">Message</label>
											{errors.comment && touched.comment ? <p className="form-errors">{errors.comment}</p> : null}
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" name="termsandcondition" id="flexCheckDefault"
											value={values.termsandcondition}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label className="form-check-label" htmlFor="flexCheckDefault">
											I accept the privacy policy and terms of use
										</label>
										{errors.termsandcondition && touched.termsandcondition ? <p className="form-errors">{errors.termsandcondition}</p> : null}
									</div>

								</div>
								<div className="col-12 text-center">
									<button type="submit" className="btnmain">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
			<section className="aboutmain">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-5" data-aos="fade-up" data-aos-delay="100">
							<h5>Presenting a different way of living.<br /> For those who look at life differently.</h5>
							<p>Introducing a lifestyle—an exclusive haven for those who embrace life from a unique
								perspective. Welcome to a realm that celebrates individuality and offers a new dimension of
								living.
							</p>
							<h3>INDIE-COMMUNE</h3>
						</div>
						<div className="col-lg-5" data-aos="fade-up" data-aos-delay="200">
							<h2>Arihant Enclave</h2>
							<h5><span>Independent homes</span> for the individual in you.</h5>
							<h5><span>Community living</span> for the socially connected in you.</h5>
							<p>Experience the comfort of your own independent home while nurturing your unique individuality.
								Engage in the warmth of community living, fostering connections that enrich your social spirit.
							</p>
						</div>
					</div>
					<div className="row" data-aos="fade-up">
						<div className="col-12">
							<OwlCarousel id="ownslider"
								margin={15}
								items={3}
								loop={false}
								autoplay={true}
								className="offers"
								{...options}>
								<div className="item">
									<div className="carddiv">
										<img src="images/sliderone-img1.png" alt="Senior Citizen Garden" />
										<p>Senior Citizen Garden</p>
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/sliderone-img2.png" alt="Play Court" />
										<p>Play Court</p>
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/sliderone-img3.png" alt="Kids Play Area" />
										<p>Kids Play Area</p>
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/overview-img3.jpg" alt="Water Feature" />
										<p>Water Feature</p>
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/overview-img4.jpg" alt="Trellis" />
										<p>Trellis</p>
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/overview-img5.jpg" alt="Step Seating" />
										<p>Step Seating</p>
									</div>
								</div>
							</OwlCarousel>
						</div>
					</div>
				</div>
			</section>
			<section className="lifestylemain" id="lifestyle">
				<div className="container">
					<div className="row">
						<div className="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
							<h2>The <br /> Arihant Enclave <br /> Lifestyle</h2>
							<p>A home reflects everything that makes you unique. It is a symbol of your journey and your
								success. That’s why,
								we are offering plots so you can build your home the way you want to.
							</p>
							<p> We also know that sharing your lives
								with a like-minded, diverse, and thriving community is what makes our lives richer. And that’s
								why, we are
								offering these plots among the convenience, security, and amenities of a gated society.</p>
						</div>
						<div className="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
							<OwlCarousel items={1} autoplay={true} className="offers">
								<div className="item">
									<img src="images/lifestyle-img1.png" alt="Arihant Enclave Lifestyle" />
								</div>
								<div className="item">
									<img src="images/lifestyle-img2.jpg" alt="Arihant Enclave Lifestyle" />
								</div>
								<div className="item">
									<img src="images/lifestyle-img3.jpg" alt="Arihant Enclave Lifestyle" />
								</div>
								<div className="item">
									<img src="images/lifestyle-img4.jpg" alt="Arihant Enclave Lifestyle" />
								</div>
							</OwlCarousel>
							<span>*The images shown are for illustration purposes only and are not an exact representation of the product.</span>
						</div>
					</div>
				</div>
			</section>
			<section className="Buildyourvillamain" data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-lg-6" data-aos="zoom-out">
							<img src="images/buildyoutvilla-img.png" alt="Build Your Villa" />
							<span>*The images shown are for illustration purposes only and are not an exact representation of
								the product.</span>
						</div>
						<div className="col-lg-6" data-aos="fade-up">
							<div className="Buildyourvillaright">
								<h2>Build Your Villa with Us</h2>
								<p>We understand your profession keeps you focussed, that’s why, we are offering services of our
									group company to
									build your own villa, the way you want to. We will understand your lifestyle choices and
									design & build your
									own villa so that you can avoid the hassles of running around.
								</p>
								<a href="/contactus">Get in Touch</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="whychooseusmain" id="whychooseus">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-10 offset-lg-1 col-12" data-aos="fade-up">
							<h2>Why Choose Us</h2>
						</div>
						<div className="col-xl-5 offset-xl-1 col-lg-5 col-md-6" data-aos="fade-up" data-aos-delay="200">
							<p>Experience the comfort of your own independent home while nurturing your unique individuality.
								Engage in
								the warmth of community living, fostering connections that enrich your social spirit.
							</p>
							<span>Come. Let us build it together.</span>
						</div>
						<div className="col-xl-5 col-lg-7 col-md-6" data-aos="fade-up" data-aos-delay="200">
							<div className="whychooseusright">
								<p>We are AU Group. Part of a name that has been partnering with people for generations. We
									consider
									expertise in real estate projects as fundamental for us, thus our goals are set on
									delivering a
									completely new lifestyle for you with reliability, transparency and commitment.
								</p>
								<span>And we too have a dream. To help you make yours come alive.</span>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="layoutplanmain" id="layout">
				<div className="container">
					<div className="row">
						<div className="col-lg-12" data-aos="fade-up">
							<h2>Layout Plan</h2>
							<img src="images/layout-img1.jpg" className="img-fluid" alt="Layout Plan" />
						</div>
						<div className="col-lg-4" data-aos="fade-left">
							<h3>Amenities</h3>
						</div>
						<div className="col-lg-8" data-aos="fade-right">
							<ul>
								<li><span>Arrival Court</span></li>
								<li><span>Driveway</span></li>
								<li><span>Senior Citizen Garden</span></li>
								<li><span>Play Court</span></li>
								<li><span>Kids Area and Garden</span></li>
								<li><span>Trellis</span></li>
								<li><span>Water Feature</span></li>
								<li><span>Step Seating</span></li>
								<li><span>Walkway</span></li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<section className="constructionupdatesmain">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="constructionupdatesdiv">
								<h2>Construction Updates</h2>
								{/* <input type="month" id="start" name="start" min="2018-03" value="2018-05" className="monthyearpicker" /> */}
								{/* <input type="month" id="bdaymonth" name="bdaymonth" className="monthyearpicker" placeholder="seelct date" /> */}
								{/* <input id="bday-month" type="month" name="bday-month" value="2001-06" />
								<input id="bday-month" type="month" name="bday-month" /> */}
								<select onChange={getData} className="form-control">
									{arihantyear.toReversed().map((arihantYearr) => {
										return (
											<option value={arihantYearr.year} selected={arihantYearr.year === selectedDrpYear ? true : null}>
												{moment(arihantYearr.year).format('MMM, YYYY')}</option>
										)
									})}
									{/* <option>Aug, 2023</option> */}
									{/* <option>Sep, 2023</option> */}
									{/* <option>Oct, 2023</option>
									<option>Nov, 2023</option>
									<option>Dec, 2023</option> */}
								</select>
							</div>

							{arihantdatabymonth?.data ?
								<OwlCarousel ref={carouselRef} margin={15} autoplay={true} className="offers" {...options} children={arihantdatabymonth?.data ? arihantdatabymonth?.data.map((x, index) => {
									return (
										<div className="item" key={index}>
											<div className="carddiv">
												<img src={imageUrl + x.image} alt="Construction Updates" />
											</div>
										</div>
									)
								}) : []} /> : ""}

							{/* <div className="item">
									<div className="carddiv">
										<img src="images/construction-img1.jpg" alt="" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/construction-img2.jpg" alt="" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/construction-img3.jpg" alt="" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Construction-1.jpg" alt="" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Construction-2.jpg" alt="" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Construction-3.jpg" alt="" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Construction-4.jpg" alt="" />
									</div>
								</div> */}
							{/* <span>*The images being displayed are for the month of August,2023.</span> */}
						</div>
					</div>
				</div>
			</section>
			<section className="enclaveVideo">
				<div>
				<iframe src="https://www.youtube.com/embed/XKYNcGxs5Ww?si=S42fKAdmI_2Lg9ch" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
			</section>
			<section className="location" data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="locationdiv">
								<div className="lectionleft">
									<ul>
										<li>20 MINS DRIVE <br /> FROM DELHI</li>
										<li className="mr-50">10 MINS DRIVE FROM EASTERN <br /> PERIPHERAL EXPRESSWAY</li>
										<li className="mr-50">40 MINS DRIVE FROM <br /> CONNAUGHT PLACE</li>
										<li>PROMINENT SCHOOLS, EDUCATIONAL <br /> INSTITUTES IN THE VICINITY</li>
									</ul>
								</div>
								<div className="locationcircle mx-auto">
									<img src="images/Location.png" alt="Location & Advantage" />
									<h3>Location & <br />Advantage</h3>
								</div>
								<div className="lectionright">
									<ul>
										<li>2 MINS DRIVE FROM 16 LANES <br /> DELHI-MEERUT EXPRESSWAY</li>
										<li className="ml-50">30 MINS DRIVE FROM <br /> AKSHARDHAM TEMPLE</li>
										<li className="ml-50">10 MINS DRIVE FROM <br /> KAVI NAGAR</li>
										<li className="ml-10">IN CLOSE PROXIMITY TO MALLS,<br /> MARKETS AND SHOPPING DESTINATIONS
										</li>
									</ul>
								</div>
							</div>
							<Link to="https://www.google.com/maps/place/AU+Arihant+Enclave/@28.6482748,77.4886054,15z/data=!4m6!3m5!1s0x390cf3aea47fb265:0xeac08b608d96b7d!8m2!3d28.6482748!4d77.4886054!16s%2Fg%2F11tmsmw0nj?entry=ttu" target="_blank">Map Location <img src="images/right-arrow.png" alt="" /></Link>
						</div>
					</div>
				</div>
			</section>
			<section className="Aubussinessmain" data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h3>AU Business Verticals</h3>
							<div className="Aubussinesslogo mx-md-auto">
								<img src="images/Residences.png" alt="Residences" />
								<img src="images/Commercial.png" alt="Commercial" />
								<a href="/advisory"><img src="images/Advisory.png" alt="Advisory" /></a>
								<a href="/service"><img src="images/Services.png" alt="Services" /></a>
								<a href="/joint-ventures"><img src="images/Joint ventures.png" alt="Joint ventures" /></a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="gallerymain" id="gallery" data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h2>Gallery</h2>
							<OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
								<div className="item">
									<div className="carddiv">
										<img src="images/gallery-img1.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/gallery-img2.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/gallery-img3.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Gallery-1.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Gallery-2.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Gallery-3.jpg" alt="GallArihant Enclave Galleryery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Gallery-4.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
								<div className="item">
									<div className="carddiv">
										<img src="images/Gallery-5.jpg" alt="Arihant Enclave Gallery" />
									</div>
								</div>
							</OwlCarousel>

						</div>
						<div className="col-lg-12">
							<span className="gallerytext">*The images shown are for illustration purposes only and are not an exact representation of
								the product.</span>
						</div>
					</div>
				</div>
			</section>
			<section className="homeloadmain" data-aos="fade-up">
				<div className="container">
					<div className="homeloanlogodiv">
						<div className="row">
							<div className="col-xl-6 col-lg-5">
								<h3>Home Loan <br /> Approved From</h3>
							</div>
							<div className="col-xl-6 col-lg-7">
								<div className="banklogomain">
									<div className="banklogodiv">
										<img src="images/hdfc-bank-logo.png" alt="HDFC Bank" />
									</div>
									<div className="banklogodiv">
										<img src="images/icici-logo.png" alt="ICICI Bank" />
									</div>
									<div className="banklogodiv">
										<img src="images/Canara-bank.png" alt="Canara Bank" />
									</div>
								</div>
								{/* <OwlCarousel margin={15} items={2} className="offers">
									<div className="item">
										<div className="banklogodiv">
											<img src="images/hdfc-bank-logo.png" alt="" />
										</div>
									</div>
									<div className="item">
										<div className="banklogodiv">
											<img src="images/icici-logo.png" alt="" />
										</div>
									</div>
									
								</OwlCarousel> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<a href="/" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="fa fa-angle-double-up"></i></a>

		</>
	);
}

export default HomePage;
