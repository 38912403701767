import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Thankyou from './Thankyou';
import Commingsoon from './Commingsoon';
// import Index from './componentsfamilyhub/Familyhub';
import Familyhub from './componentsfamilyhub/Familyhub';
import ThankyouFamilyHub from './Thankyoufamilyhub';
import Mainpage from './homepage/Mainpage';
import Aboutus from './homepage/Aboutus';
import Newspage from './homepage/Newspage';
import Contactus from './homepage/Contactus';
import Commingsoonpage from './homepage/Commingsoonpage';
import Privacypolicy from './homepage/Privacypolicy';
import Terms from './homepage/Terms';
import Disclaimer from './homepage/Disclaimer';
import Advisorycomingsoon from './homepage/Advisorycomingsoon';
import Servicecomingsoon from './homepage/Servicecomingsoon';
import Jointcomingsoon from './homepage/Jointcomingsoon';
import Career from './homepage/Career';
import Service from './homepage/Service';
import Advisory from './homepage/Advisory';
import Jointventures from './homepage/Joint-ventures';
import Thankyoumain from './Thankyou-main';
import Blogs from './homepage/Blog';
import Residences from './homepage/Residences';
import Commercial from './homepage/Commercial';
import Blogdetail from './homepage/Blog-detail';
import Blogdetail2 from './homepage/Blog-detail2';
import Blogdetail3 from './homepage/Blog-detail3';
import Blogdetail5 from './homepage/Blog-detail5';
import Blogdetail6 from './homepage/Blog-detail6';
import Blogdetail7 from './homepage/Blog-detail7';
import Blogdetail8 from './homepage/Blog-detail8';
import Blogdetail9 from './homepage/Blog-detail9';
import Blogdetail10 from './homepage/Blog-detail10';
import Blogdetail11 from './homepage/Blog-detail11';
import Blogdetail12 from './homepage/Blog-detail12';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
       <Routes>
       <Route path='/' element={<Mainpage />} />
                <Route path='/commingsoon' element={<Commingsoon />} />
                <Route path='/arihant-enclave' element={<App />} />
                <Route path='/thank-you' element={<Thankyou />} />
                <Route path='/family-hub' element={<Familyhub />} />
                <Route path='/thank-you-family-hub' element={<ThankyouFamilyHub />} />
                <Route path='/aboutus' element={<Aboutus />} />
                <Route path='/news' element={<Newspage />} />
                <Route path='contactus' element={<Contactus />} />
                <Route path='contactus' element={<Contactus />} />
                <Route path='commingsoonpage' element={<Commingsoonpage />} />
                <Route path='privacy-policy' element={<Privacypolicy />} />
                <Route path='disclaimer' element={<Disclaimer />} />
                <Route path='terms' element={<Terms />} />
                <Route path='advisorycomingsoon' element={<Advisorycomingsoon />} />
                <Route path='servicecomingsoon' element={<Servicecomingsoon />} />
                <Route path='jointcomingsoon' element={<Jointcomingsoon />} />
                <Route path='career' element={<Career />} />
                <Route path='service' element={<Service />} />
                <Route path='advisory' element={<Advisory />} />
                <Route path='joint-ventures' element={<Jointventures />} />
                <Route path='thankyou' element={<Thankyoumain />} />
                <Route path='residences' element={<Residences />} />
                <Route path='commercial' element={<Commercial />} />
                <Route path='blogs' element={<Blogs />} />
                <Route path='examining-indias-latest-real-estate-budget' element={<Blogdetail />} />
                <Route path='exploring-2024-trends-in-real-estate-market-in-india' element={<Blogdetail2 />} />
                <Route path='key-considerations-for-purchasing-residential-plots-in-ghaziabad' element={<Blogdetail3 />} />
                <Route path='exploring-essential-drivers-in-real-estate-growth-and-investment' element={<Blogdetail5 />} />
                <Route path='what-makes-investing-in-residential-property-in-ghaziabad-a-smart-choice' element={<Blogdetail6 />} />
                <Route path='wealth-building-101-a-guide-to-choosing-the-right-property-investment-company' element={<Blogdetail7 />} />
                <Route path='community-connectivity-the-impact-of-location-on-commercial-spaces' element={<Blogdetail8 />} />
                <Route path='how-do-ghaziabads-residential-plots-offer-a-canvas-for-personalized-dream-homes' element={<Blogdetail9 />} />
                <Route path='beyond-the-ordinary-6-features-that-define-a-lavish-home' element={<Blogdetail10 />} />
                <Route path='how-to-choose-the-right-real-estate-project-in-ghaziabad' element={<Blogdetail11 />} />
                <Route path='what-to-expect-from-a-leading-property-investment-company' element={<Blogdetail12 />} />
  </Routes>
  </BrowserRouter>
);
