import React, { useState, useEffect } from 'react'
import "./mainpage.css"
import "./mainresponsive.css"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Header from './Header';
import Footer from './Footer';
import Getintouch from './Getintouch';
import Metatag from './Metatag';

import { auphilosopy, aubussiness, uacorporate, aufoundermessage, auvalues, autestimonial } from '../local-api/index';

function Mainpage() {
	const [bannerVideo, setBannerVideo] = useState(true);
	const [videoplay, setVideoPlay] = useState(null);
	const [show, setShow] = useState(`${auvalues?.accordionImg[0]?.img}`);

	const handleVideoClick = () => {
		setVideoPlay(true);
	}

	const handleAccordionClick = (index) => {
		const imagesUrl = auvalues?.accordionImg.map((element) => {
			return element.img
		})
		console.log(imagesUrl, 'images')
		setShow(imagesUrl[index]);
	};

	let options = {
		loop: false,
		margin: 20,
		nav: false,
		autoplay: true,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 3
			},
			1000: {
				items: 3.3
			}
		}
	};

	let options1 = {
		loop: false,
		margin: 0,
		nav: false,
		navText: ["<img src='/websiteimages/left-arrow-slider.png'>", "<img src='/websiteimages/right-arrow-slider.png'>"],
		autoplay: false,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 1
			},
			1000: {
				items: 1
			}
		}
	}

	const [width, setWidth] = useState(0);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setWidth(window.innerWidth);
		setIsMobile(width <= 768);

		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [width]);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	return (
		<React.Fragment>
			<Metatag
				link="/"
				title="Real Estate Investment in India: Ghaziabad Properties | AU"
				description="Explore lucrative real estate investment opportunities in Ghaziabad, India. Discover prime properties for investment with Aurea Real Estate."
			/>
			<Header />
			<Getintouch />
			<div className='aurealestate-banner'>
				{bannerVideo&&<ReactPlayer className='themeplay' playing={true} url='https://www.youtube.com/watch?v=sHGCvlNHRtQ?controls=0&showinfo=0'
					autoPlay loop muted={bannerVideo} allowFullScreen priority={'true'} />}
				{
					bannerVideo ?
						<div className='videoplaydiv'>
							<img src='/websiteimages/volume-off-indicator.png' alt='volume-off' onClick={() => setBannerVideo(false)} />
						</div> :
						<div className='videoplaydiv'>
							<img src='/websiteimages/speaker-filled-audio-tool.png' alt='volume-On' onClick={() => setBannerVideo(true)} />
						</div>
				}
			</div>
			<div className='aurealestate-auphilosopy'>
				<div className='aurealestate-auphilosopyline'>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<h2>AU Philosophy</h2>
							<p>AU Group's philosophy revolves around integrity, innovation, and unwavering dedication to clients. We believe in transparent practices, superior service, and fostering a sense of pride in ownership. Our commitment to these values defines our path towards excellence in the real estate industry.</p>
						</div>
					</div>
				</div>
				<img src='/websiteimages/au-philosopy-img.jpg' alt='AU Philosophy' width='600' height='295' />
			</div>

			{/* On scroll */}
			<div className='aurealestate-bussiness' data-aos="fade-up" data-aos-duration="500" >
				<div className='container'>
					<h2>{aubussiness?.heading}</h2>
					<div className='row'>
						<div className='col-lg-12'>
							<div className="aurealestate-bussinessimgmaintop">
								{aubussiness?.bussinessimgmaintop?.map((element, i) => {
									return <div key={i} className="aurealestate-bussinessimgdiv">
										<img src={element?.src} alt={element?.alt} />
										<div className="aurealestate-bussinesscontentdiv">
											<a href={element?.url} target='_blank'>
												<img src={element?.logoSrc} alt={element?.logoAlt} loading='lazy' />
												<p>{element?.text}</p>
											</a>
										</div>
									</div>
								})}
							</div>
							<div className="aurealestate-bussinessimgmainbottom">
								{aubussiness?.bussinessimgmainbottom?.map((element, i) => {
									return <div key={i} className="aurealestate-bussinessimgdivbottom">
										<img src={element?.src} alt={element?.alt} loading='lazy'  />
										<div className="aurealestate-bussinesscontentdivbottom">
											<Link to={element?.url} >
												<img src={element?.logoSrc} alt={element?.logoAlt} loading='lazy' />
												<p>{element?.text}</p>
											</Link>
										</div>
									</div>
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='exceeding-expectations' data-aos="fade-up" data-aos-duration="500">
				<h2>{uacorporate?.heading}</h2>
				<div className="video-div" data-aos="fade-up">
					<div id="ytvideoclick" className="ytvideo" data-video={uacorporate?.dataVideo}
						style={{ backgroundImage: `url(${uacorporate?.backgroundImg})` }}
						onClick={() => handleVideoClick()}
					>
						{
							videoplay == null ?
								<div className="wrapper">
									<div className="video-main">
										<div className="promo-video">
											<div className="waves-block">
												<div className="waves wave-1"></div>
												<div className="waves wave-2"></div>
												<div className="waves wave-3"></div>
											</div>
										</div>
										<a href="javascript:void(0)" className="video video-popup mfp-iframe"><i className="fa fa-play"></i></a>
									</div>
								</div>
								: <iframe class="youtubevideo" src={uacorporate?.youTubeVidio} title="YouTube video player" autoplay frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
						}
					</div>
				</div>
			</div>
			<div className='foundermessage-main' data-aos="fade-up" data-aos-duration="500">
				<div className='foundermessage-line'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='foundermessage-div'>
									<div className='foundermessage-leftdiv'>
										<h3>{aufoundermessage?.heading}</h3>
										<p>{aufoundermessage?.text1}</p>
										<p>{aufoundermessage?.text2}</p>
										<h4 dangerouslySetInnerHTML={{ __html: aufoundermessage?.subHeading }}></h4>
									</div>
									<div className='foundermessage-rightdiv'>
										<img src={aufoundermessage?.founderImg} alt={aufoundermessage?.imgAlt} loading='lazy' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='auvalues-main' data-aos="fade-up" data-aos-duration="500">
					<div className='auvalues-auvaluesline'>
					</div>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<h3>{auvalues?.heading}</h3>
							</div>
							<div className='col-xl-6 col-lg-6'>
								<img src={show} className='mapmainclass' alt={auvalues?.accordionImg[0]?.alt} loading='lazy' />
							</div>
							<div className='col-xl-6 col-lg-6'>
								<div className="accordion" id="accordionExample">
									<div className="accordion-item" onClick={() => handleAccordionClick(0)}>
										<h2 className="accordion-header" id={auvalues?.accordionItem[0]?.uniqueHeadingId}>
											<button className="accordion-button" type="button" data-related-map={auvalues?.accordionItem[0]?.dataRelatedMap} data-bs-toggle="collapse" data-bs-target={`#${auvalues?.accordionItem[0]?.uniqueTabId}`} aria-expanded="true" aria-controls={auvalues?.accordionItem[0]?.uniqueTabId}>
												{auvalues?.accordionItem[0]?.heading}
											</button>
										</h2>
										<div id={auvalues?.accordionItem[0]?.uniqueTabId} className="accordion-collapse collapse show" aria-labelledby={auvalues?.accordionItem[0]?.uniqueHeadingId} data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: auvalues?.accordionItem[0]?.text }}></p>
											</div>
										</div>
									</div>
									{auvalues?.accordionItem?.map((element, i) => {
										if (i > 0) {
											return <div key={i} className="accordion-item" onClick={() => handleAccordionClick(i)}>
												<h2 className="accordion-header" id={element?.uniqueHeadingId}>
													<button className="accordion-button collapsed" type="button" data-related-map={element?.dataRelatedMap} data-bs-toggle="collapse" data-bs-target={`#${element?.uniqueTabId}`} aria-expanded="false" aria-controls={element?.uniqueTabId}>
														{element?.heading}
													</button>
												</h2>
												<div id={element?.uniqueTabId} className="accordion-collapse collapse" aria-labelledby={element?.uniqueHeadingId} data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<p dangerouslySetInnerHTML={{ __html: element?.text }}></p>
													</div>
												</div>
											</div>
										}
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='testimonial-main' data-aos="fade-up" data-aos-duration="500">
				<OwlCarousel margin={15} autoplay={true} className="offers" {...options1}>
					{autestimonial?.map((element, i) => {
						return <div className='item' key={i}>
							<div className='testimonial-left'>
								<ReactPlayer className='themeplayone' url={element?.url}
									allowFullScreen loading='lazy' />
							</div>
							<div className='testimonial-right'>
								<p>{element?.text}</p>
								<div className='testimonial-rightone'>
									<h4>{element?.name}</h4>
								</div>
							</div>
						</div>
					})}
				</OwlCarousel>

			</div>
			<div className='inthenews-main' data-aos="fade-up" data-aos-duration="500">
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='inthenewsdivtop'>
								<h3>In the News</h3>
								<a href='/news'>View All <img src='/websiteimages/right-arrow.png' alt='' /></a>
							</div>
						</div>
					</div>
				</div>
				<div className='newsslider'>
					<OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
						<div className='item'>
							<div className='newsdiv'>
								<a rel="noreferrer" href='https://realty.economictimes.indiatimes.com/news/industry/survey-soon-circle-rates-in-noida-to-be-revised-after-three-years/102819803' target='_blank'>
									<img src='/websiteimages/news-img1.jpg' alt='News' loading='lazy' />
									<p>Survey soon, circle rates in Noida to be revised after three years</p>
									<h4>Read  More</h4>
								</a>
							</div>

						</div>
						<div className='item'>
							<div className='newsdiv'>
								<a rel="noreferrer" href='https://realty.economictimes.indiatimes.com/news/rera/register-projects-with-rera-safeguard-interests-of-buyers-up-rera-chairman-to-promoters/102962641' target='_blank'>
									<img src='/websiteimages/news-img2.jpg' alt='News' loading='lazy' />
									<p>Register projects with RERA, safeguard interests of buyers: UP-RERA chairman to promoters</p>
									<h4>Read  More</h4>
								</a>
							</div>
						</div>
						<div className='item'>
							<div className='newsdiv'>
								<a rel="noreferrer" href='https://realty.economictimes.indiatimes.com/news/industry/noida-may-allot-plots-via-draw-of-lots-interviews-not-e-auction/104291740' target='_blank'>
									<img src='/websiteimages/news-img3.jpg' alt='News' loading='lazy' />
									<p>Noida may allot plots via draw of lots, interviews, not e-auction</p>
									<h4>Read  More</h4>
								</a>
							</div>
						</div>
					</OwlCarousel>
				</div>
			</div>
			<Footer />
		</React.Fragment>
	)
}

export default Mainpage